
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget = window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget || {};
window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget.add_to_calendar_widget = window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget.add_to_calendar_widget || {};
window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget.add_to_calendar_widget = {...window.Smartly.locales.modules.en.back_royal.add_to_calendar_widget.add_to_calendar_widget, ...{
    "add_to_calendar": "Add to Calendar",
    "add_to_calendar_select": "Add to calendar ▾",
    "download": "Download"
}
}
    