import angularModule from 'AddToCalendarWidget/angularModule/scripts/add_to_calendar_widget_module';
import * as userAgentHelper from 'userAgentHelper';
import { OuiBuilder, OuiEvent } from 'OuiCalendarEvent';
import { saveAs } from 'file-saver';
import template from 'AddToCalendarWidget/angularModule/views/add_to_calendar_widget.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('addToCalendarWidget', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            scope: {
                eventId: '<',
                eventTitle: '<',
                eventStartDate: '<',
                eventEndDate: '<',
                eventUrl: '<?',
                eventDescription: '<?',
                eventAddress: '<?',
                mobileLabelLocale: '@?',
                desktopLabelLocale: '@?',
            },
            templateUrl,
            link(scope) {
                //----------------------------
                // Initialization
                //----------------------------

                scope.mobileLabelLocale =
                    scope.mobileLabelLocale || 'add_to_calendar_widget.add_to_calendar_widget.add_to_calendar';
                scope.desktopLabelLocale =
                    scope.desktopLabelLocale || 'add_to_calendar_widget.add_to_calendar_widget.add_to_calendar_select';

                scope.modelProxy = {
                    showCalendarOptions: false,
                };

                //----------------------------
                // Scope Methods & Helpers
                //----------------------------

                // There may be cases where the parent wants to close the calendar options dropdown
                // when an event occurs. For example, the parent may want the widget dropdown to
                // close if the user clicks outside of it. In this case, the parent can $broadcast
                // an the `closeCalendarOptions` event and this directive will close the dropdown
                // when it registers the event being triggered.
                scope.$on('closeCalendarOptions', () => {
                    scope.closeCalendarOptions();
                });

                scope.closeCalendarOptions = () => {
                    scope.modelProxy.showCalendarOptions = false;
                };

                const calendarEvent = new OuiEvent({
                    id: scope.eventId,
                    title: scope.eventTitle,
                    startDate: scope.eventStartDate,
                    endDate: scope.eventEndDate,
                    url: scope.eventUrl,
                    description: scope.eventDescription,
                    address: scope.eventAddress,
                });
                scope.calendarEvent = calendarEvent; // Note: put on scope for testing

                // In Cordova we'll use a calendar plugin
                scope.isCordova = !!$window.CORDOVA;

                // If we detect a mobile or tablet device, but we aren't in Cordova, then we assume
                // we are in a mobile or tablet browser. In this case we'll just show a button that
                // will download an ICS. Chrome and mobile Safari will directly
                // launch the system calendar. Others, like Firefox, will download the ICS file, which
                // can then be tapped to open the system calendar.
                scope.isMobileOrTabletBrowser = !$window.CORDOVA && userAgentHelper.isMobileOrTabletDevice();

                // We use this check to conditionally hide the functionality altogether because
                // saveAs, which is used to download the ICS file, has some documented issues in iOS and iPadOS.
                // See https://github.com/eligrey/FileSaver.js#ios
                // See https://github.com/eligrey/FileSaver.js/issues/581
                // See https://github.com/eligrey/FileSaver.js/issues/586
                scope.isiOSoriPadOS = userAgentHelper.isiOSoriPadOSDevice();

                // Otherwise, in desktop browsers we'll give options to open Google or Microsoft
                // calendar http links, or just download the ICS for opening in a desktop program.

                scope.addToCalendar = calendarMethod => {
                    if (calendarMethod === 'cordova') {
                        // The plugin uses some logic to calculate the allday option that seems
                        // weird to me. It sees if the end time - the start time is exactly a day(s) by checking
                        // if modulo 0, and sets allday to true if so. That does not seem correct, as it could be
                        // a non-allday event that spans multiple days but the times happen to be the same. This
                        // threw me off at first when my test event was in that situation. Someone made a tweak to
                        // allow passing an allday option on Android (see https://git.io/JeGIc), but for some reason
                        // the iOS side does not seem to respect the allday option (see https://git.io/JeGzc). I'm
                        // going to make an issue and maybe a fix on the plugin's repo, but for now it is probably
                        // enough of an edge case to not worry about.
                        // FIXME: Make an issue on the repo about this
                        const calOptions = {
                            allday: false,
                        };

                        // On Android the url option will append to the description field. We DO NOT
                        // want that behavior because we are instead appending the url to the
                        // description field ourselves.
                        if (!userAgentHelper.isAndroidDevice()) {
                            calOptions.url = calendarEvent.url;
                        }

                        // The Miya Miya Cordova app does not use the Calendar plugin. This code shouldn't be reachable in Miya Miya
                        // because we don't have the student network, but being defensive anyway.
                        $window.plugins.calendar?.createEventInteractivelyWithOptions(
                            calendarEvent.title,
                            calendarEvent.address,
                            calendarEvent.description,
                            calendarEvent.startDate,
                            calendarEvent.endDate,
                            calOptions,
                            () => {},
                            () => {
                                $window.alert(`Failed to add ${calendarEvent.title} to calendar.`);
                            },
                        );
                    } else if (calendarMethod === 'google') {
                        NavigationHelperMixin.loadUrl(OuiBuilder.google(calendarEvent), '_blank');
                    } else if (calendarMethod === 'office365') {
                        // Office 365 (paid) Outlook accounts
                        NavigationHelperMixin.loadUrl(OuiBuilder.outlook(calendarEvent, 'office'), '_blank');
                    } else if (calendarMethod === 'outlookLive') {
                        // Personal (free) Outlook accounts
                        NavigationHelperMixin.loadUrl(OuiBuilder.outlook(calendarEvent, 'live'), '_blank');
                    } else if (calendarMethod === 'ics') {
                        const icsBlob = OuiBuilder.ics(calendarEvent, 'Quantic Holdings, Inc.', 'blob');
                        const hyphenatedTitle = calendarEvent.title.replace(/ +/g, '-').toLowerCase(); // https://stackoverflow.com/a/4310084/1747491
                        saveAs(icsBlob, `${hyphenatedTitle}.ics`);
                    }

                    scope.closeCalendarOptions();
                };
            },
        };
    },
]);
